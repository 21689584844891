export const speiseplan = {    
    periode:  "27.01. - 31.01.2025",
    
    zusatz:  "11:30 Uhr - 13:30 Uhr",

    montag: {
        sichtbar: true,
        gericht: "Tagessuppe",
        beigabe: "",
        preis: "7,00 €"
    },

    dienstag: {
        sichtbar: true,
        gericht: "Szegediner Gulasch",
        beigabe: "mit Klößen",
        preis: "9,90 €"
    },


    mittwoch: {
        sichtbar: true,
        gericht: "Rotes Linsencurry",
        beigabe: "mit Hähnchenfleisch",                                   
        preis: "9,90 €"
    },

    donnerstag: {
        sichtbar: true,
        gericht: "Kochfisch",
        beigabe: "mit Dillsoße und Kartoffeln",
        preis: "9,90 €"
    },

    freitag: {
        sichtbar: true,
        gericht: "Tagessuppe",
        beigabe: "",
        preis: "7,00 €"
    },
};
